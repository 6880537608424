<template>
    <div class="k-text-center k-w-full">
        <div class="dropdownDisplay">
            <div v-show="!props.isLimitedDate">
                <KDropDownList :data-items="timePeriods" :text-field="'text'" :data-item-key="'id'" :value="selectedValue"
                               :default-item="selectedValue" @change="handleSelectionChange">
                </KDropDownList>
            </div>
        </div>
        <div v-show="showDateRangePickerDiv">
            <KButton :theme-color="'info'" :size="'small'" :fill-mode="'outline'" :class="'k-m-sm'"
                     @click="cancelDateRangePicker">Cancel</KButton>
            <KDateRangePicker :class="'datePicker'" :show="showDateRangePickerObj"
                              :value="rangePickerValue"
                              :start-date-input="'CustomStartDateInput'" :end-date-input="'CustomEndDateInput'"
                              @change="handlePickerChange">
                <template v-slot:CustomStartDateInput="{ props }">
                    <CustomStartDateInput v-bind="props" />
                </template>
                <template v-slot:CustomEndDateInput="{ props }">
                    <CustomEndDateInput v-bind="props" />
                </template>
            </KDateRangePicker>
        </div>
        <div class="dateDisplay">
            <div class="k-text-primary" v-show="showDates === true">
                {{ displayStartDate }} - {{ displayEndDate }}
                <KButton :theme-color="'info'" :size="'small'" :fill-mode="'outline'" :class="'k-button-noborder'"
                         @click="openDateRangePicker"><i class="fa-light fa-pencil"></i></KButton>
            </div>
        </div>
    </div>
</template>

<style>
    .datePicker {
        display: inline-block !important;
    }

    .dateDisplay {
        min-height: 27px;
    }

    .dropdownDisplay {
        min-height: 33px;
    }
</style>

<script setup>

    import { DropDownList as KDropDownList } from "@progress/kendo-vue-dropdowns";
    import { DateRangePicker as KDateRangePicker } from "@progress/kendo-vue-dateinputs";
    import { Button as KButton } from '@progress/kendo-vue-buttons';
    import CustomStartDateInput from "./CustomStartDateInput.vue";
    import CustomEndDateInput from "./CustomEndDateInput.vue";
    import moment from "moment";
    import { ref, defineProps, defineEmits, onMounted, onBeforeMount } from 'vue';

    // Data
    let startDate = ref(null);
    let endDate = ref(null);
    let displayStartDate = ref(null);
    let displayEndDate = ref(null);
    let showDateRangePickerDiv = ref(false);
    let showDateRangePickerObj = ref(true);
    let showDates = ref(false);
    let rangePickerValue = ref({
        start: null,
        end: null,
    });
    let selectedValue = ref({ text: "Please Select", id: "select" });
    const timePeriods = ref([
        { text: "Today", id: "td" },
        { text: "This Week", id: "tw" },
        { text: "Last Week", id: "lw" },
        { text: "Last Month", id: "lm" },
        { text: "Month To Date", id: "mtd" },
        { text: "Last Quarter", id: "lq" },
        { text: "Year To Date", id: "ytd" },
        { text: "Quarter To Date", id: "qtd" },
        { text: "Q1 This Year", id: "q1this" },
        { text: "Q2 This Year", id: "q2this" },
        { text: "Q3 This Year", id: "q3this" },
        { text: "Q4 This Year", id: "q4this" },
        { text: "Q1 Last Year", id: "q1last" },
        { text: "Q2 Last Year", id: "q2last" },
        { text: "Q3 Last Year", id: "q3last" },
        { text: "Q4 Last Year", id: "q4last" },
        { text: "Last Year", id: "ly" },
        { text: "Custom", id: "custom" },
        { text: "All", id: "all" },
    ]);

    // Props
    const props = defineProps([
        'period',
        'setdates',
        'isLimitedDate'
    ]);

    // Emits
    const emit = defineEmits(['settingDates', 'showNumericValue', 'change']);

    // Before Mount
    onBeforeMount(() => {
        closeDateRangePicker();
        showDates.value = false;

        if (props.period !== undefined && props.period !== null && props.period !== '') {

            selectedValue.value = timePeriods.value.find(({ id }) => id === props.period);
            if (selectedValue.value !== undefined) {
                if (selectedValue.value.id !== "custom") {
                    var triggerevent = { value: selectedValue.value, save: false };
                    handleSelectionChange(triggerevent);
                }
                else {
                    showDates.value = false;

                    if (props.setdates !== null && props.setdates.start !== null && props.setdates.end !== null) {
                        startDate.value = moment(props.setdates.start);
                        endDate.value = moment(props.setdates.end);
                        rangePickerValue.value.start = new Date(startDate.value);
                        rangePickerValue.value.end = new Date(endDate.value);
                        closeDateRangePicker();
                        showDates.value = true;
                        emitDates();
                    }
                }
            }
        }
    });

    // Mounted
    onMounted(() => {
        if (selectedValue.value !== undefined) {
            if (startDate.value === null && endDate.value === null && selectedValue.value.id === 'select') {
                closeDateRangePicker();
                showDates.value = false;
            }
        }
    });
      
    // Methods
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    function openDateRangePicker() {
        showDates.value = false;
        showDateRangePickerDiv.value = true;
        sleep(100).then(() => { showDateRangePickerObj.value = true; });
        document.addEventListener('keydown', handleESCKeyPress);
    }

    function closeDateRangePicker() {
        showDateRangePickerObj.value = false;
        showDateRangePickerDiv.value = false;
        if (selectedValue.value.id === "custom") {
            showDates.value = true;
        }
        document.removeEventListener('keydown', handleESCKeyPress);
    }

    function cancelDateRangePicker() {
        closeDateRangePicker();
        rangePickerValue.value.start = new Date(startDate.value);
        rangePickerValue.value.end = new Date(endDate.value);
    }

    const handleSelectionChange = function (event) {

        selectedValue.value = event.value;

        if (selectedValue.value.id != "select") {
            if (selectedValue.value.id !== "custom") {
                closeDateRangePicker();
                calculateDates(selectedValue.value.id);
                event.save = true;
                emitDates(event.save);

            } else {
                rangePickerValue.value.start = null;
                rangePickerValue.value.end = null;
                openDateRangePicker();
                showDates.value = false;
                emit("settingDates");
            }
        }

        emit("showNumericValue", { rValue: false });
    }

    const handlePickerChange = function (event) {
        rangePickerValue.value = event.value;
        if (rangePickerValue.value.start != null &&
            rangePickerValue.value.end != null &&
            moment(rangePickerValue.value.start).isValid() &&
            moment(rangePickerValue.value.end).isValid()) {

            startDate.value = moment(rangePickerValue.value.start);
            endDate.value = moment(rangePickerValue.value.end).endOf('day');

            if (endDate.value > startDate.value) {
                event.save = true;
                emitDates(event.save);
                closeDateRangePicker();
            }
        }
    }

    const calculateDates = function (selectedValue) {

        switch (selectedValue) {
            case 'td':
                startDate.value = moment().startOf('day');
                endDate.value = moment().endOf('day');
                break;
            case 'tw':
                startDate.value = moment().startOf('isoWeek');
                endDate.value = moment().endOf('isoWeek');
                break;
            case 'lw':
                startDate.value = moment().subtract(1, 'weeks').startOf('isoWeek');
                endDate.value = moment().subtract(1, 'weeks').endOf('isoWeek');
                break;
            case 'lm':
                startDate.value = moment().subtract(1, 'months').startOf('month');
                endDate.value = moment().subtract(1, 'months').endOf('month');
                break;
            case 'mtd':
                startDate.value = moment().startOf('month');
                endDate.value = moment().endOf('day');
                break;
            case 'lq':
                startDate.value = moment().subtract(1, 'quarters').startOf('quarter');
                endDate.value = moment().subtract(1, 'quarters').endOf('quarter');
                break;
            case 'ytd':
                startDate.value = moment().startOf('year');
                endDate.value = moment().endOf('day');
                break;
            case 'qtd':
                startDate.value = moment().startOf('quarter');
                endDate.value = moment().endOf('day');
                break;
            case 'q1this':
                startDate.value = moment().quarter(1).startOf('quarter');
                endDate.value = moment().quarter(1).endOf('quarter');
                break;
            case 'q2this':
                startDate.value = moment().quarter(2).startOf('quarter');
                endDate.value = moment().quarter(2).endOf('quarter');
                break;
            case 'q3this':
                startDate.value = moment().quarter(3).startOf('quarter');
                endDate.value = moment().quarter(3).endOf('quarter');
                break;
            case 'q4this':
                startDate.value = moment().quarter(4).startOf('quarter');
                endDate.value = moment().quarter(4).endOf('quarter');
                break;
            case 'q1last':
                startDate.value = moment().subtract(1, 'years').quarter(1).startOf('quarter');
                endDate.value = moment().subtract(1, 'years').quarter(1).endOf('quarter');
                break;
            case 'q2last':
                startDate.value = moment().subtract(1, 'years').quarter(2).startOf('quarter');
                endDate.value = moment().subtract(1, 'years').quarter(2).endOf('quarter');
                break;
            case 'q3last':
                startDate.value = moment().subtract(1, 'years').quarter(3).startOf('quarter');
                endDate.value = moment().subtract(1, 'years').quarter(3).endOf('quarter');
                break;
            case 'q4last':
                startDate.value = moment().subtract(1, 'years').quarter(4).startOf('quarter');
                endDate.value = moment().subtract(1, 'years').quarter(4).endOf('quarter');
                break;
            case 'ly':
                startDate.value = moment().subtract(1, 'years').startOf('year');
                endDate.value = moment().subtract(1, 'years').endOf('year');
                break;
            case 'all':
                startDate.value = moment('1900-01-01').startOf('day');
                endDate.value = moment('2099-12-31').endOf('day');
                break;
        }
    }

    const emitDates = function (emitChange) {
        if (selectedValue.value.id !== "custom") {
            showDates.value = false;
        } else {
            showDates.value = true;
        }

        if (startDate.value !== undefined && startDate.value !== null) {
            displayStartDate.value = startDate.value.format("MM/DD/YYYY");
            startDate.value = startDate.value.format("MM/DD/YYYY");
        }

        if (endDate.value !== undefined && endDate.value !== null) {
            displayEndDate.value = endDate.value.format("MM/DD/YYYY");
            endDate.value = endDate.value.format("MM/DD/YYYY HH:mm:ss");
        }

        if (emitChange == undefined || (emitChange !== undefined && emitChange))
            emit("change", { selectedTimePeriod: selectedValue.value.id, startDate: startDate.value, endDate: endDate.value });
    }
     
    function handleESCKeyPress(event) {
        if (event.keyCode !== 27) { 
            return
        }
        cancelDateRangePicker();       
    }
</script>
