<template>
	<div class="topHeader">
		<div class="topHeaderLeft">
			<div @click="onSiteLogoClick">
				<img :src="require('/src/assets/images/strm_logo_icon.png')" class="site-logo" alt="Streamline Health logo" />
			</div>
			<KendoMenu id="eValuatorMenu" :items="evaluatorMenuItems" @select="onMenuItemSelect" v-show="showMenu">
			</KendoMenu>
		</div>
		<div class="topHeaderRight" v-show="showMenu">
			<KendoMenu id="mainMenu" :items="mainMenuItems" @select="onMenuItemSelect"></KendoMenu>
			<span class="spanEncounterSearch">
				<EncounterSearch></EncounterSearch>
			</span>
			<KendoMenu id="profileMenu" :items="profileMenuItems" @select="onMenuItemSelect"></KendoMenu>
		</div>
	</div>
</template>

<style>
	.site-logo {
		cursor: pointer
	}

	.topHeader {
		display: flex;
		min-width: 100%;
	}

	.topHeaderLeft {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		min-width: 50%;
		padding-left: 20px;
	}

	.topHeaderRight {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		min-width: 50%;
		padding-right: 20px;
	}

	.k-menu:not(.k-context-menu) {
		border: none;
		background-color: transparent;
	}

	.k-menu-expand-arrow {
		color: var(--purple-default);
	}

	/* Evaluator menu */
	#eValuatorMenu {
		padding-top: 5px;
	}

	#eValuatorMenu .k-menu > .k-first > .k-menu-link > .k-menu-link-text {
		font-size: 2.2em;
		font-weight: bold;
		color: var(--violet-default);
	}

	/* Main menu */
	#mainMenu {
		margin: 0 25px;
	}

	#mainMenu .k-menu-link-text {
		font-size: 1.3em;
		color: var(--purple-default);
	}

	/* Profile menu */
	#profileMenu {
		margin: 10px 25px;
	}

	.profileImg .k-menu-link-text {
		font-family: 'Font Awesome 6 Pro';
		font-size: 1.5em;
		color: var(--violet-default);
	}

	/* Menu caret display */
	#eValuatorMenu .k-menu-expand-arrow {
		font-size: 2em;
		display: block;
	}
	#mainMenu .k-menu-expand-arrow,
	#profileMenu .k-menu-expand-arrow {
		font-size: 2em;
		display: none;
	}

	.k-icon.k-font-icon.k-i-caret-alt-down {
		font-size: 1em;
	}

	@media only screen and (max-width: 1500px) {
	}

	@media only screen and (max-width: 1200px) {
		#mainMenu .k-menu-link-text {
			font-size: 1em;
		}
	}

	@media only screen and (max-width: 1000px) {
	}
</style>

<script setup>

	import { Menu as KendoMenu } from '@progress/kendo-vue-layout'
	import { fetchAPI } from '../../modules/fetchAPI'
	import EncounterSearch from '../common/EncounterSearch.vue'
	import { useRoute } from 'vue-router'
	import { watch, ref, onMounted } from 'vue'
	import { useCommonStore } from '../../store/useCommonStore'
    import router from '../../router';

	const commonStore = useCommonStore();

	let evaluatorMenuItems = ref([]);
	let mainMenuItems = ref([]);
	let profileMenuItems = ref([]);
	let menusLoaded = ref(false);
	let showMenu = ref(true);

	const location = useRoute();

	watch(location, (newroute) => {
		if (!menusLoaded.value && newroute.path !== '/login') {
			loadMenus();
		}
	})

	onMounted(() => {
		setTimeout(() => {
			showMenu.value = commonStore.userLoggedIn();
		}, 1000);
		loadMenus();
	})

	async function loadMenus() {
		if (!commonStore.userLoggedIn()) return;

		const response = await fetchAPI("api/Menu/GetMainMenus/", "", "GET");

		if (response.ok) {

			const menus = await response.json();

			if (menus != null && menus.evaluatorMenu != null) {
				evaluatorMenuItems.value = menus.evaluatorMenu;
			}

			if (menus != null && menus.mainMenu != null) {
				mainMenuItems.value = menus.mainMenu;
			}

			if (menus != null && menus.profileMenu != null) {
				profileMenuItems.value = menus.profileMenu;
			}

			menusLoaded.value = true;
		}
		else {
			showMenu.value = false;
		}
	}	

	function onMenuItemSelect(item, itemId) {
		if (item.item.data != null) {
			window.open(item.item.data);
			return false;
		}
	}

	function onSiteLogoClick() {
		router.push({ path:'/' })
	}

</script>
