import { createApp } from 'vue'
import router from './router'
import logger from './modules/logger'
import { createPinia } from 'pinia'
import App from './App.vue'
import ConfirmationDialog from "./components/common/ConfirmationDialog.vue";
import InformationDialog from "./components/common/InformationDialog.vue";
import { Loader } from '@progress/kendo-vue-indicators'
import config from '../config.js';

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(router);
app.use(logger);

// Global variables from the config.js file that can be imported anywhere using import.
app.provide('config', config);

app
    .component('KLoader', Loader)
    .component('ConfirmationDialog', ConfirmationDialog)
    .component('InformationDialog', InformationDialog)
    ;

app.mount('#app');
