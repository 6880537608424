<template>
    <div class="logout">
        <h1>Logging out!</h1>
    </div>
</template>

<script setup>
    import { onMounted } from 'vue'
    import { useCommonStore } from '../store/useCommonStore';
    let redirectToClassic = (Uri) => {       
        if (Uri === null) {
            fetch('api/account/token')
                .then(r => {                   
                    return r.json();
                })
                .then(json => {
                    window.location.replace(json.eValuatorUri + '/Account/MyLogOff');
                });
        } else {
            window.location.replace(Uri + '/Account/MyLogOff');
        }                
    }
    onMounted(() => {
        const store = useCommonStore();
        let eValuatorUri = store.userLoggedIn() ? store.eValuatorUri : null;
        store.clearToken();
        sessionStorage.clear();
        redirectToClassic(eValuatorUri);
    });
</script>